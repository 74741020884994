import { nanoid } from 'nanoid';
import thumbnailImg from '../images/thumbnail-img.png';

// HEAD DATA
export const headData = {
  title: 'Phil Browne | Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  image: thumbnailImg,
  description: 'Phil Browne - Software Engineer based in the Washington, DC area.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi! My name is',
  name: 'Phil Browne',
  subtitle: `I'm a Software Engineer based in the Washington, DC area.`,
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile-photo.jpg',
  paragraphOne:
    'Hello and thank you so much for visiting my page! I am a software engineer with experience developing applications with JavaScript, React.js, Node.js, Express.js, Python, and Flask. I am passionate about helping people through technology, making the world more accessible and inclusive for people of all backgrounds, and finding ways to efficiently solve problems to make a positive difference.',
  paragraphTwo:
    'I have a background of more than a decade as a professional musician as well as several years of experience in communications and administration. I would love to explore ways that I can help your organization succeed.',
  resume: 'https://philipbrowne.github.io/resume/', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.png',
    title: 'Voter Info',
    info: 'Full-Stack Voter Information Application using Google Civic Information, EasyPost, and MapQuest APIs.  Designed to increase voter engagement and provide easily accessible location-specific information to US Voters.  Completed as part of Springboard Software Engineering Fellowship Curriculum.',
    info2:
      'Technologies Used: JavaScript, jQuery, Bootstrap, AJAX, Axios, Python, Flask, PostgreSQL, Bcrypt, SQLAlchemy',
    url: 'https://www.voter-info.org/',
    repo: 'https://github.com/philipbrowne/us-voter-info', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.png',
    title: 'Space Force',
    info: 'Full-Stack Platform Game Built in 7 Days for Mintbean.io Hiring Hackathon. Mintbean Star Award Winner for "exemplary achievement in the field of web development."',
    info2:
      'Technologies Used: JavaScript, PhaserJS, JavaScript State Machine, Bootstrap, Python, Flask, PostgreSQL, Bcrypt, SQLAlchemy',
    url: 'https://space-force-game.herokuapp.com/',
    repo: 'https://github.com/philipbrowne/space-force', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.png',
    title: 'Card Wars',
    info: 'Collaborative Front-End Card Game Built in 7 Days for Mintbean.io Hiring Hackathon.  Mintbean Star Award Winner for "exemplary achievement in the field of web development."  Project completed as part of three-person team with Springboard fellowship colleagues Otman Bouchari and Hau Ngo.',
    info2: 'Technologies Used: JavaScript, Bootstrap, jQuery, and ViteJS',
    url: 'https://card-wars.netlify.app/',
    repo: 'https://github.com/obouchari/War-Card-Game', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project4.png',
    title: 'Lights Out',
    info: 'Front End Puzzle Game Built Using React.js.  Based on Popular Tiger Electronic Game from 1995.  Project completed as part of Springboard Software Engineering Curriculum.',
    info2: 'Technologies Used: JavaScript, React.js, React Hooks',
    url: 'https://react-lights-out-board-game.netlify.app/',
    repo: 'https://github.com/philipbrowne/react-lights-out', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Please feel free to reach out, I would love to hear from you!',
  btn: 'Email Me',
  email: 'pbrowne@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/philbrownetech/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/philipbrowne',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/PhilBuildsStuff',
    },
    {
      id: nanoid(),
      name: 'envelope',
      url: 'mailto:pbrowne@gmail.com',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
